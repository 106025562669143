<template>
  <div>
    <div v-if="localParams?.pageSource?.type && localParams.pageSource.type === 'SITEMAP'" class="row">
      <div class="row">
        <div class="col-12">
          Sitemap URLs:
        </div>
      </div>
      <div class="row">
        <div class="col-12">
          <n-input type="textarea" placeholder="URLs" v-model:value="sitemapURLs" />
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-6">
        Weight Supplier
      </div>
      <div class="col-6">
        <n-select :options="[
            { label: 'None', value: 'NONE' },
            { label: 'Category Accumulator', value: 'CATEGORYACCUMULATOR' },
        ]"
        v-model:value="showWeightSupplierType"
        />
      </div>
    </div>
    <div v-if="localParams.weightSupplier?.type && localParams.weightSupplier.type === 'CATEGORYACCUMULATOR'">
      <div class="row">
        <div class="col-6">
          Reader:
        </div>
        <div class="col-6">
          <n-select :options="readerOptions" v-model:value="localParams.weightSupplier.params.reader" />
        </div>
      </div>
      <div class="row">
        <div class="col-6">
          Reader Field:
        </div>
        <div class="col-6">
          <n-select :options="availableReaderFields" v-model:value="localParams.weightSupplier.params.readerField"/>
        </div>
      </div>
      <div class="row">
        <div class="col-6">
          Categoriser:
        </div>
        <div class="col-6">
          <n-select :options="categoriserOptions" v-model:value="localParams.weightSupplier.params.categoriser" />
        </div>
      </div>
      <div class="row">
        <div class="col-6">
          Category:
        </div>
        <div class="col-6">
          <n-select :options="availableCategories" v-model:value="localParams.weightSupplier.params.category"/>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
 import _ from "lodash";
 import { NSelect, NInput } from "naive-ui";
 import hcReaderFields from "@/assets/resources/hardcoded_reader_fields.json";
 import categoriserDetails from "@/assets/resources/categoriser_interface_info.json";

 export default {
   name: "NewURLReader",
   data: function() {
     return {
       localParams: _.cloneDeep(this.sourceParams),
       showWeightSupplierType: this.sourceParams?.weightSupplier?.type ?? "NONE",
       sitemapURLs: (this.sourceParams?.pageSource?.params?.urls ?? []).join("\n")
     }
   },
   mounted() {
     if (_.isUndefined(this.localParams.pageSource)) {
       // At the moment we only support sitemap sources.

       this.localParams.pageSource = {
         type: "SITEMAP",
         params: {
           urls: []
         }
       }
     }
   },
   watch: {
     showWeightSupplierType() {
       if (this.showWeightSupplierType === 'NONE') {
         delete this.localParams.weightSupplier;
       } else {
         this.localParams.weightSupplier = {
           type: this.showWeightSupplierType,
           params: {}
         }
       }
     },
     localParams: {
       handler() {
         this.$emit("paramsChanged", this.localParams);
       },
       deep: true
     },
     sitemapURLs() {
       this.localParams.pageSource.params.urls = this.sitemapURLs.split("\n");
     }
   },
   computed: {
     readerOptions() {
       return _.map(this.availableReaders, reader => ({
           label: reader.sourceLabel,
           value: reader.sourceLabel
       }))
     },
     categoriserOptions() {
       return _.map(this.availableCategorisers, categoriser => ({
         label: categoriser.categoriserLabel,
         value: categoriser.categoriserLabel
       }))
     },
     availableReaderFields() {
       if (_.isUndefined(this.localParams?.weightSupplier?.params?.reader)) return [];

       // If the reader being used is a type with hardcoded fields then use those. If not then get the fields defined in the params object.
       const selectedReader = _.find(this.availableReaders, reader => reader.sourceLabel === this.localParams.weightSupplier.params.reader);
       const selectedReaderType = selectedReader.sourceType;

       if (_.has(hcReaderFields, selectedReaderType)) {
         return _.map(hcReaderFields[selectedReaderType], fieldName => ({
           value: fieldName,
           label: fieldName
         }))
       } else {
         return _.map(selectedReader.sourceParams.fieldDefs, fieldDef => ({
           value: fieldDef.fieldTitle,
           label: fieldDef.fieldTitle
         }))
       }
     },
     availableCategories() {
       if (_.isUndefined(this.localParams?.weightSupplier?.params?.categoriser)) return [];

       const selectedCategoriser = _.find(this.availableCategorisers, categoriser => categoriser.categoriserLabel === this.localParams.weightSupplier.params.categoriser);
       const selectedCategoriserLabel = selectedCategoriser.categoriserLabel;

       if (_.has(categoriserDetails, selectedCategoriserLabel)) {
         return _.map(categoriserDetails[selectedCategoriserLabel].categories, category => ({
           value: category,
           label: category
         }))
       } else {
         // This should only happen if this is a custom categoriser in which case there will only be one category - the one stored in the categoryName property.
         return [{
             value: selectedCategoriser.categoryName,
             label: selectedCategoriser.categoryName
         }];
       }
     }
   },
   components: {
     NSelect, NInput
   },
   props: [ "sourceParams", "availableReaders", "availableCategorisers" ]
 }
</script>
<style scoped>

</style>