<template>
    <div class="settingsContainer">
      <div class="settingsGlobalContainer">
          <ConfigPortlet :can-scroll="false">
              <template v-slot:header>Global</template>
              <template v-slot:content>
                  <div class="row">
                      <div class="col-4">Start Date</div>
                      <div class="col-4">Granularity</div>
                      <div class="col-4">Auto-Canonicalise</div>
                  </div>
                  <div class="row">
                      <div class="col-4">
                          <n-date-picker value-format="yyyy-MM-dd" v-model:formatted-value="localConfig.startDate"/>
                      </div>
                      <div class="col-4">
                          <n-select :options="[
                            { label: 'All', value: 'ALL' },
                            { label: 'Year', value: 'YEAR' },
                            { label: 'Quarter', value: 'QUARTER' },
                            { label: 'Month', value: 'MONTH' },
                            { label: 'Week', value: 'WEEK' },
                            { label: 'Day', value: 'DAY' }
                          ]" v-model:value="localConfig.granularity" />
                      </div>
                      <div class="col-4">
                          <n-checkbox :checked="localConfig.autoCanonicalise" @updateChecked="v => localConfig.autoCanonicalise = v"/>
                      </div>
                  </div>
                  <div class="row">
                      <div class="col-12">Scorecard Name</div>
                  </div>
                  <div class="row">
                      <div class="col-12">
                        <n-input v-model:value="localConfig.scorecardName" />
                      </div>
                  </div>
              </template>
          </ConfigPortlet>
      </div>
      <div class="settingsItemContainer">
        <div class="settingsRow">
          <div class="settingsComponent">
            <ConfigPortlet>
              <template v-slot:header>
                Readers
              </template>
              <template v-slot:content>
                <n-collapse>
                  <n-collapse-item v-for="(reader, index) in localConfig.sources" v-bind:key="index" :title="reader.sourceLabel">
                    <reader-config :reader="reader"
                                   :available-categorisers="localConfig.categorisers"
                                   :available-readers="localConfig.sources"
                                   @readerChanged="(changedReader) => readerChanged(index, changedReader)"
                    />
                  </n-collapse-item>
                </n-collapse>
              </template>
              <template v-slot:footer>
                <n-button @click="addReader">Add Reader</n-button>
                <n-button @click="authGoogle">Authenticate for GA and GSC</n-button>
              </template>
            </ConfigPortlet>
          </div>
          <div class="settingsComponent">
            <ConfigPortlet>
              <template v-slot:header>
                Filters
              </template>
              <template v-slot:content>
                <n-collapse>
                  <n-collapse-item v-for="(filter, index) in localConfig.filters" v-bind:key="index">
                    <filter-config :filter="filter" @filterChanged="(changedFilter) => filterChanged(index, changedFilter)"/>
                  </n-collapse-item>
                </n-collapse>
              </template>
              <template v-slot:footer>
                <n-button @click="addFilter">Add Filter</n-button>
              </template>
            </ConfigPortlet>
          </div>
        </div>
        <div class="settingsRow">
          <div class="settingsComponent">
            <config-portlet>
              <template v-slot:header>
                Categorisers
              </template>
              <template v-slot:content>
                <CategoriserConfig :categoriser-config="localConfig.categorisers" @categorisers-changed="categorisersChanged"/>
              </template>
            </config-portlet>
          </div>
          <div class="settingsComponent">
            <config-portlet>
              <template v-slot:header>
                Scorer
              </template>
              <template v-slot:content>
                <scorer-config :scorer-config="localConfig.scorer" :global-config="localConfig" @scorerChanged="scorerChanged" />
              </template>
            </config-portlet>
          </div>
        </div>
      </div>
    </div>
</template>

<script>
import { NCollapseItem, NCollapse, NButton, NDatePicker, NSelect, NCheckbox, NInput } from "naive-ui";
import ReaderConfig from "@/components/ReaderConfig";
import _ from "lodash";
import FilterConfig from "@/components/FilterConfig";
import ScorerConfig from "@/components/ScorerConfig";
import CategoriserConfig from "@/components/categoriseritems/CategoriserConfig";
import ConfigPortlet from "@/ConfigPortlet";
import axios from "axios";

export default {
  name: "EasyConfig",
  components: {
    ConfigPortlet,
    CategoriserConfig, ScorerConfig, FilterConfig, ReaderConfig, NCollapseItem, NCollapse, NButton, NDatePicker, NSelect, NCheckbox, NInput
  },
  props: [ "configJson", "jobId" ],
  data() {
    return {
      localConfig: _.cloneDeep(this.configJson)
    }
  },
  methods: {
    addReader() {
      this.localConfig.sources.push({});
    },
    addFilter() {
      this.localConfig.filters.push({
        filterParameters: {}
      });
    },
    readerChanged(readerIndex, changedReader) {
      this.localConfig.sources[readerIndex] = changedReader;
    },
    filterChanged(filterIndex, changedFilter) {
      this.localConfig.filters[filterIndex] = changedFilter;
    },
    categorisersChanged(changedCategorisers) {
      this.localConfig.categorisers = changedCategorisers;
    },
    scorerChanged(changedScorer) {
      this.localConfig.scorer = changedScorer;
    },
    authGoogle() {
        // AP - Google's JS API is loaded in index.html, as it is not a module, suppress linting error
        // eslint-disable-next-line no-undef
        const client = google.accounts.oauth2.initCodeClient({
            client_id: '757611243796-sidm6mejitghluqbue9b9jcifgia97d2.apps.googleusercontent.com',
            scope: "https://www.googleapis.com/auth/analytics.readonly https://www.googleapis.com/auth/webmasters.readonly",
            ux_mode: "popup",
            callback: (response) => {
                console.log('Id: ' + this.jobId);
                console.log('Code: ' + response.code);

                if (response && response.code) {
                    this.$auth0.getAccessTokenSilently().then((token) => {
                        axios.post(process.env.VUE_APP_JOB_API_URL + '/job/googleAuth/' + this.$route.params.id,
                            response,
                            { headers: { Authorization: 'Bearer ' + token } });
                    }).catch(() => console.log("Error getting auth token"));
                }
            }
        });

      client.requestCode();
    }
  },
  watch: {
    localConfig: {
      handler() {
        this.$emit('configUpdated', this.localConfig)
      },
      deep: true
    }
  }
}
</script>

<style scoped>
  .settingsGlobalContainer {

  }

  .settingsContainer {
      display: flex;
      flex: 1 1 0px;
      flex-direction: column;
  }

  .settingsItemContainer {
    display: flex;
    flex: 1 1 0px;
    flex-direction: column;
  }

  .settingsRow {
    display: flex;
    flex: 1 1 0px;
    flex-direction: row;
  }

  .settingsComponent {
    display: flex;
    flex: 1 1 0px;
  }

  .n-tab-pane {
    height: 100%;
  }
</style>