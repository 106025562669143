<template>
  <div>
    <n-collapse>
      <n-collapse-item title="Scorer Setup">
          <div class="row">
              <div class="col-6">
                  Series Combiner
              </div>
              <div class="col-6">
                  Point Combiner
              </div>
          </div>
          <div class="row">
              <div class="col-6">
                  <n-select v-model:value="localScorer.seriesCombiner.type" :options="[
                    {
                      value: 'SumAll',
                      label: 'SumAll'
                    }
                  ]" />
              </div>
              <div class="col-6">
                  <n-select v-model:value="localScorer.pointCombiner" :options="[
                    {
                      value: 'Additive',
                      label: 'Additive'
                    }
                  ]" />
              </div>
          </div>
      </n-collapse-item>
      <n-collapse-item title="Field Weights">
        <scorer-field v-for="(fieldWeight, index) in localScorer.fields" v-bind:key="index" :weight-settings="fieldWeight" :reader-fields="readerFields"
                      @weightsChanged="changedWeight => weightChanged(index, changedWeight)"
        />
        <n-button @click="addFieldWeight">Add Field Weight</n-button>
      </n-collapse-item>
      <n-collapse-item title="Category Weights">
        <category-weight v-for="(categoryWeight, index) in localScorer.categoryWeights" v-bind:key="index"
                         :availableCategorisers="globalConfig.categorisers" :category-weight-settings="categoryWeight"
                         @catWeightsChanged="changedCatWeight => categoryWeightChanged(index, changedCatWeight)"
        />
        <n-button @click="addCategoryWeight">Add Category Weight</n-button>
      </n-collapse-item>
      <n-collapse-item title="Range Weights">
        <range-weight v-for="(rangeWeight, index) in localScorer.fieldRangeWeights" v-bind:key="index" :weight-settings="rangeWeight" :reader-fields="readerFields"
                        @rangeWeightsChanged="changedRangeWeight => rangeWeightChanged(index, changedRangeWeight)"
        />
        <n-button @click="addRangeWeight">Add Range Weight</n-button>
      </n-collapse-item>
    </n-collapse>
  </div>
</template>

<script>
import _ from 'lodash';
import ScorerField from "@/components/scoreritems/ScorerField";
import {NButton, NCollapse, NCollapseItem, NSelect} from "naive-ui";
import CategoryWeight from "@/components/scoreritems/CategoryWeight";
import RangeWeight from "@/components/scoreritems/RangeWeight.vue";
//import Categorisers from "@/assets/resources/categorisers.json";
import hcReaderFields from "@/assets/resources/hardcoded_reader_fields.json";

export default {
  name: "ScorerConfig",
  components: {NCollapse, RangeWeight, CategoryWeight, ScorerField, NButton, NCollapseItem, NSelect},
  data() {
    return {
      localScorer: _.cloneDeep(this.scorerConfig)
    }
  },
  methods: {
    addFieldWeight() {
      if (_.isUndefined(this.localScorer.fields)) {
        this.localScorer.fields = [];
      }

      this.localScorer.fields.push({});
    },
    addCategoryWeight() {
      if (_.isUndefined(this.localScorer.categoryWeights)) {
        this.localScorer.categoryWeights = [];
      }

      this.localScorer.categoryWeights.push({});
    },
    addRangeWeight() {
      if (_.isUndefined(this.localScorer.fieldRangeWeights)) {
        this.localScorer.fieldRangeWeights = [];
      }

      this.localScorer.fieldRangeWeights.push({});
    },
    weightChanged(weightIndex, changedWeight) {
      this.localScorer.fields[weightIndex] = changedWeight;
    },
    categoryWeightChanged(catWeightIndex, changedCatWeight) {
      this.localScorer.categoryWeights[catWeightIndex] = changedCatWeight;
    },
    rangeWeightChanged(rangeWeightIndex, changedRangeWeight) {
      this.localScorer.fieldRangeWeights[rangeWeightIndex] = changedRangeWeight;
    }
  },
  watch: {
    localScorer: {
      handler() {
        this.$emit("scorerChanged", this.localScorer);
      },
      deep: true
    }
  },
  computed: {

    readerFields() {
      /*
       * Convert the readers from the global config to an object keyed by reader names in which every attribute is a list
       * of fields belonging to that reader.
       */
      const keyedObj = _.keyBy(this.globalConfig.sources, 'sourceLabel');

      return _.mapValues(keyedObj, reader => {
        // If this a reader type with hardcoded fields then return those fields. Otherwise take the fields from the reader definition
        if (_.has(hcReaderFields, reader.sourceType)) {
          return hcReaderFields[reader.sourceType]
        } else {
          if (_.isUndefined(reader.sourceParams.fieldDefs)) return [];

          return _.map(reader.sourceParams.fieldDefs, 'fieldTitle');
        }
      })
    }
  },
  props: ["scorerConfig", "globalConfig"]
}
</script>

<style scoped>

</style>